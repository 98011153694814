body,
#root,
.container {
  height: 100vh;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
}

nav {
  padding: 0.25em 0.5em;
  flex: 0 0;
  button {
    margin-top: 0.5em;
    margin-bottom: 0.5em;
    + button {
      margin-left: 1em;
    }
    border: 1px solid grey;
    background-color: whitesmoke;
    &.selected {
      color: white;
      background-color: grey;
    }
  }
}

.redoc-container {
  flex: 1 1 auto;
  overflow: auto;
}
